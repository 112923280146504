<template>
  <div >
    
      <RecordProcessStepper v-if="CurrentEntity.EntityProcesses && CurrentEntity.EntityProcesses.length > 0"
      :System="System" :SystemEntities="SystemEntities" :Record="Record"
      :OperationalDB="ItemOperationalDB" :CurrentEntity="CurrentEntity"
      />
    <v-dialog v-model="InvoicePaymentDialog" max-width="800">
        <v-card tile  width="100%" flat v-if="Record">
          <v-card-title class="justify-space-between recordtoolbar white--text mediumoverline">
            Payment Receipt<v-spacer></v-spacer>{{Record.id}}
          </v-card-title>
          <v-card-text>
            <FieldValueEditerComponent v-for="field in PaymentReceiptHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="paymentreceipt" :field="field" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelectPOP"
                />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="CloseInvoicePaymentDialog()" dark color="warning">
              Cancel
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UploadPOP()">Save</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
     <v-dialog persistent v-model="StateDialog" max-width="400">
      <v-card v-if="Record && Record.id && StatusField">
        <v-card-title>
          {{Record[CurrentEntity.Primary_Field_Name]}} Status
        </v-card-title>
        <v-card-text>
          <v-select :items="StatusField.Options" return-object item-text="Name" v-model="NewStatus" label="Status"/>
          <v-select :items="Record.Status.Options" return-object item-text="Name" v-model="NewStatusReason" label="Status"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="DeactivateStateDialog()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateItemStatus()" dark color="success">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <InvoicePrepandExport v-if="userisDebtorsClerk && CashLoanEntity || userisDebtorsClerk && StoreOrderEntity" :Record="Record" :CurrentEntity="CurrentEntity" :LookupObj="LookupObj" :RADB="RADB" :System="System" :SystemEntities="SystemEntities"
    :PrepInvoice="PrepInvoice" @DeactivateStateDialog="DeactivateStateDialog"
    />
    <v-dialog v-model="ReceivingDialog" max-width="800">
      <v-card>
        <v-card-title class="recordtoolbar white--text">
          Goods Receiving
        </v-card-title>
        <v-card-text style="padding-top: 20px;">           
              <FieldValueEditerComponent v-for="field in ReceivingFields" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="editedReceivingItem" :field="field" @onPhotoFileselected="onPhotoFileselected"
                  :SystemEntities="SystemEntities" :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                  @UploadFileSelect="UploadFileSelectAlternate"
                    />
        </v-card-text>
        <v-card-actions class="recordtoolbar white--text">
          <v-btn dark class="warning" @click="DeactivateReceivingDialog()">
            <v-icon>mdi-cancel</v-icon>
            Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn dark class="success" @click="SaveReceivingItem()">
            <v-icon>mdi-content-save</v-icon>
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-card class="siteconfigcard background" >
      <!-- still need status -->
         <v-list class="transparent justify-center" style="padding-top:20px;margin-top: 30px;">
    <v-list-item dark class="justify-center" style="margin-top: -20px;border-radius: 3px;background-color: #272727;">
      <v-list-item-action>
        <v-btn  :small="MobileView" v-if="!MiniView" dark class="orange" @click="ReduceQuickStep()">Back</v-btn>
        <v-icon :small="MobileView" v-if="MiniView" dark
        
        class="orange soloactionicon" @click="ReduceQuickStep()">mdi-chevron-left</v-icon>
      </v-list-item-action>
      <!-- <v-list-item-content>
      <v-list-item-title class="justify-center">
        {{CurrentEntity.DisplayName}}
      </v-list-item-title>
      </v-list-item-content> -->
      <v-list-item-content style="text-align: center;">
        <v-list-item-title :class="MobileView ? 'overline' : ''">
        {{Record[CurrentEntity.Primary_Field_Name]}}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon :style="MobileView ? 'vertical-align: top;' : ''" :small="MobileView" v-if="CurrentEntity.Table_Icon">
            {{CurrentEntity.Table_Icon}}
          </v-icon>
          {{CurrentEntity.SingleName}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-btn outlined @click="CopyRecordLink(Record)" v-if="Record.CanView">
        <span v-if="!MobileView">Copy Link</span>
        <v-icon>mdi-link</v-icon>
        </v-btn>
    <!-- <v-btn color="error" @click="ViewItem(Record)">
      <span v-if="!MobileView">Edit Layout</span>
        <v-icon>mdi-monitor-dashboard</v-icon>
      </v-btn> -->
    </v-list-item>
     <v-list-item style="width: 80%;">
    <v-btn @click="ActivateStateDialog(item)" :disabled="!Record.CanEdit" style="text-transform: none !important;" depressed text plain>
      <v-icon left>
            mdi-power
            </v-icon>
        {{Record.Status_Reason && Record.Status_Reason.Name ? Record.Status_Reason.Name : 'No Status Reason - '}} ({{Record.Status && Record.Status.Name ? Record.Status.Name : 'No Status'}})
        </v-btn>
     </v-list-item>
     <v-list-item v-if="SalesInvoiceReady && SalesInvoicecanhavePayment">
      <v-btn dark
    color="success" @click="ActivateInvoicePaymentDialog()">Receive Payment</v-btn>  
     </v-list-item>
      <v-list-item v-if="Record.Next_Invoice_Due">
       <v-btn dark color="error" outlined style="width: 100%;" @click="PrepInvoice = true">
        Create New Invoice
       </v-btn>
      </v-list-item>
    <v-list-item @click="InviteFieldValueUser('User')" v-if="UserCompanyUserInvite"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            {{UserCompanyUserInviteText}}
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-reply</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="CurrentEntity && CurrentEntity.id === 'User_Companies' && Record.CanEdit && !GuestView" >
          <v-switch @change="UpdateUserCompanyProp('Account_Limits_by_Admin',Record.Account_Limits_by_Admin)" v-model="Record.Account_Limits_by_Admin" label="Company User sets Account Limits"/>
        </v-list-item>
        <v-list-item v-if="CurrentEntity && CurrentEntity.id === 'User_Companies' && Record.CanEdit && !GuestView" >
          <v-select label="Loan Entities" clearable @change="UpdateUserCompanyProp('LoanEntities',Record.LoanEntities)" multiple :items="ActiveLoanOrderEntities" item-text="id" v-model="Record.LoanEntities" />
        </v-list-item>
        <v-list-item v-if="CurrentEntity && CurrentEntity.id === 'User_Companies' && Record.CanEdit && !GuestView" >
          <v-select label="Store Entities" clearable @change="UpdateUserCompanyProp('StoreEntities',Record.StoreEntities)" multiple :items="ActiveStoreOrderEntities" item-text="id" v-model="Record.StoreEntities"/>
        </v-list-item>
        <!-- <v-list-item @click="AddfieldOnceOff()">
          AddfieldOnceOff
        </v-list-item> -->
    <v-list-item @click="ActivateReceivingDialog()" v-if="CanReceiveGoods"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Receive Goods
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-warehouse</v-icon>
          </v-list-item-action>
        </v-list-item>
     
    <v-list-item class="background darken-1 mediumoverline font-weight-regular"  v-if="OwnerSecurity" >
      
      <!-- <v-list-item-content>
        <v-list-item-title :class="MobileView ? 'overline' : ''">
        {{Record[CurrentEntity.Primary_Field_Name]}}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon :style="MobileView ? 'vertical-align: top;' : ''" :small="MobileView" v-if="CurrentEntity.Table_Icon">
            {{CurrentEntity.Table_Icon}}
          </v-icon>
          {{CurrentEntity.SingleName}}
        </v-list-item-subtitle>
      </v-list-item-content> -->
      <v-list-item-content v-if="OwnerSecurity" style="text-align: -webkit-right;">
        <v-list-item-title :class="MobileView ? 'overline' : ''">
          <v-icon :small="MobileView">mdi-office-building</v-icon>
        {{Record.Business_Unit ?Record.Business_Unit.Name: ''}}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          >
            <template v-slot:activator="{ on }">
          <v-chip style="padding: 2px;" :small="MobileView" outlined v-on="on">
           <v-avatar size="35">
              <v-img :src="Record.Owner.Profile_Photo" v-if="Record.Owner && Record.Owner.Profile_Photo"/>
              <v-img src="@/assets/BlankProfilePic.png" v-if="!Record.Owner || !Record.Owner.Profile_Photo"/>
              </v-avatar>
          {{Record.Owner ? Record.Owner.Full_Name: ''}}
          </v-chip>
           </template>
           <v-card tile max-width="350">
             <v-card-text>
           <UserLookup :UsersLookupArray="PotentialOwnerUsersArray" :ModelProp="'Owner'" :RecordObj="Record" @UpdateUserLookupProp="UpdateOwningUser"
            :FieldLabel="'Owner'" :ReadOnly="false"
            :Rules="[]"
            />
             </v-card-text>
           </v-card>
          </v-menu>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="QuickMode === 'QuickEdit'">
        <v-btn v-if="!MiniView" dark class="success" @click="save(true)">Save</v-btn>
        <v-icon v-if="MiniView" dark class="success soloactionicon" @click="save(true)">mdi-content-save</v-icon>
      </v-list-item-action>
    </v-list-item>
    
      <v-list-item @click="GuestView = !GuestView" v-if="CurrentEntity && CurrentEntity.id === 'User_Companies'">
        Toggle Guest View
      </v-list-item>
         </v-list>
              <v-card-title v-if="CalendarTypeEntity && Record && Record.StartObject" style="width:100%;padding-bottom: 0px;" class="my-1 justify-center mediumoverline SoftBuilderGradient">{{Record.Title}}
                    <v-spacer>
                    </v-spacer>
                    <div>
                     <v-chip-group class="caption" style="padding-bottom: 0px;">
                      <span><v-chip small color="success">Starting</v-chip>{{ Record.StartObject.toGMTString() }}</span>
                    </v-chip-group>
                    <v-chip-group class="caption">
                      <span><v-chip small color="warning">Ending</v-chip>{{ Record.EndObject.toGMTString() }}</span>
                    </v-chip-group>
                    </div>
                  </v-card-title>
                  <v-card-text style="min-height: 400px;padding-top: 0px;">
                    <v-layout row class="justify-center">
                      <div class="flex xl10 lg10 md10 sm11 xs12">
                    <v-card-text :style="MobileView ? 'padding: 0px;' : 'padding-top: 0px;'" v-if="GuestView">
                      <MyCompanyComp @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="[]"
                      :SubscriptionPackages="[]" :CompanyRecord="Record"
                      @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
                    </v-card-text>
                    <v-card-text :style="MobileView ? 'padding: 0px;' : 'padding-top: 0px;'" v-if="!GuestView">
                        <TableRecordView v-if="QuickMode === 'RecordView'" :System="System" :SystemEntities="SystemEntities" :Record="Record"
                        :CurrentEntity="CurrentEntity" :RADB="RADB" :OperationalDB="ItemOperationalDB" :ConfigDB="ConfigDB" :SystemPhotoLibraries="SystemPhotoLibraries"
                        :RAApp="RAApp" :SystemGuides="SystemGuides" @ActivateActiveTab="ActivateActiveTab"
                        :ActiveSectionType="ActiveSectionType" :ActiveTab="ActiveTab" :ActiveTabIndex="ActiveTabIndex"
                        :ActiveSectionIndex="ActiveSectionIndex" :ActiveSection="ActiveSection"
                        @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar"
                    /> 
                    <QuickEditComponent v-if="QuickMode === 'QuickEdit'" :System="System" :SystemEntities="SystemEntities" :Record="Record" :QuickEditSections="QuickEditSections"
                    @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage" :SystemPhotoLibraries="SystemPhotoLibraries"
                    @AddGallerySubcolImages="AddGallerySubcolImages" :SystemBuilderTable="SystemBuilderTable" :CurrentEntity="CurrentEntity"
                    /> 
                     
                    <AllMyTasksandAppointments v-if="QuickMode === 'QuickActivities'" :SystemEntities="SystemEntities" @RemoveActivity="RemoveActivity" :RelatedObj="RelatedObj"
                    :UnreadNotifications="ActiveUnreadNotifications" :CurrentEntity="CurrentEntity" :Record="Record"
                            :ActivityType="ComputedActivityType" :UserRecord="userLoggedIn" :AllEvents="AppointmentsArray" :ActivitiesArray="ActivitiesArray" :TeamView="true"
                        />
                        <RecordLiaison  v-if="QuickMode === 'QuickLiaison'" :RecordData="Record" :ActiveSession="CurrentEntity" :LiaisonDialogActive="LiaisonDialogActive"
                        :OperationalDB="ItemOperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                        @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog" :FullonCreate="true"
                        :System="System" :SystemEntities="SystemEntities" :CanEdit="Record.CanEdit" :ActiveRecord="Record.ActiveProcess"
                        />
                        <Timesheets :RelatedObj="RelatedObj" v-if="QuickMode === 'QuickTimesheets'"
                          :System="System" :SystemEntities="SystemEntities"
                          />
                    </v-card-text>
                    <!-- <div style="margin-left:80px;">
                    <h2>Hoverable Sidenav Buttons</h2>
                    <p>Hover over the buttons in the left side navigation to open them.</p>
                  </div> -->
                      </div>
                    </v-layout>
                  </v-card-text>
            <!-- <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="Record" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            /> -->
   </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import QuickEditComponent from '@/components/Database/QuickEditComponent'
import MyCompanyComp from '@/components/Client/MyCompanyComp'
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments';
import RecordLiaison from '@/components/General/RecordLiaison';
import Timesheets from '@/components/SuitePlugins/Timesheets/Timesheets';
import TableRecordView from '@/components/Database/TableRecordView';
import UserLookup from '@/components/General/UserLookup'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import InvoicePrepandExport from '@/components/Database/InvoicePrepandExport';
import RecordProcessStepper from '@/components/SuitePlugins/Pipelines/RecordProcessStepper'
export default {
    props: ['System','SystemEntities','CurrentEntity','Record','RADB','QuickMode','SystemPhotoLibraries','SystemGuides','RAApp','UnreadNotifications',
    'ActiveSectionType','ActiveTab','ActiveTabIndex','ActiveSectionIndex','ActiveSection'],
    components: {
        QuickEditComponent,
        MyCompanyComp,
        AllMyTasksandAppointments,
        RecordLiaison,
        Timesheets,
        TableRecordView,
        UserLookup,
        FieldValueEditerComponent,
        InvoicePrepandExport,
        RecordProcessStepper
        },
    data() {
        return {
          
          InvoicePaymentDialog: false,
          paymentreceipt: {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          },
          PaymentReceiptHeaders: [
            {id: 'Amount_Paid',DisplayName: 'Amount Paid', Name: 'Amount_Paid',Type: 'Number Field'},
            {id: 'SelectedPOPFile',DisplayName: 'POP File', Name: 'SelectedPOPFile',Type: 'Single File Upload'},
          ],
          GuestView: false,
          NewStatus: '',
          NewStatusReason: '',
          PrepInvoice: false,
          StateDialog: false, 
          ReceivingDialog: false,
          editedReceivingItem: {},
          editedReceivingItemIndex: -1,
          ReceivingFields: [
            {id: 'Reference_Number',DisplayName: 'Reference Number', Name: 'Reference_Number',Type: 'Single Line Text'},
            {id: 'Qty',DisplayName: 'Qty', Name: 'Qty',Type: 'Number Field'},
            {id: 'Date_Received',DisplayName: 'Date Recieved', Name: 'Date_Received',Type: 'Date'},
            {id: 'Price',DisplayName: 'Price', Name: 'Price',Type: 'Number Field'},
            {id: 'Receiving_Document',DisplayName: 'Receiving Document', Name: 'Receiving_Document',Type: 'Single File Upload'},
            {id: 'Progress',DisplayName: 'Progress', Name: 'Progress',Type: 'Option Set',Options: [{ID: 1000000, Name: 'Received'},{ID: 1000001,Name: 'Inspection'},{ID: 1000002,Name: 'Completed'}]}
          ],
          ActiveUnreadNotifications: [],
          RecordQuickNavItems: [
            {Name: 'Record',Icon: 'mdi-database',Color: 'blue-grey',Prop: 'RecordView'},
            {Name: 'Quick Edit',Icon: 'mdi-pencil',Color: 'warning',Prop: 'QuickEdit',ComputedProp: 'QuickEditEntity'},
            {Name: 'Activities',Icon: 'mdi-clipboard-list',Color: 'purple',Prop: 'QuickActivities',Pluginid: 'Activities_and_Team'},
            {Name: 'Timesheets',Icon: 'mdi-account-clock',Color: 'success',Prop: 'QuickTimesheets',Pluginid: 'Timesheets'},
            {Name: 'Liaison',Icon: 'mdi-comment-outline',Color: 'indigo',Prop: 'QuickLiaison'},
            {Name: 'PDF',Icon: 'mdi-file-pdf-box',Color: 'error',Prop: 'QuickPDF',Pluginid: 'PDF_Builder'}
          ] ,     
          LiaisonDialogActive: false,
        }
    },	
    computed:{
      
      SalesInvoiceReady(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Sales Invoice'
        && this.Record && this.Record.Latest_PDF_File && this.Record.Progress && this.Record.Progress.Name === 'Invoiced' && this.Record.Client
        && this.Record.Owner &&this.Record.BillingAccount
        // && this.Record.BillingAccount.Owner I realize why we wanted this but...I don't think we would have an invoice if ba has no owner right?
      },
      SalesInvoicecanhavePayment(){
        return this.Record.Progress.Name === 'Invoiced' && this.userisDebtorsClerk
      },
      userisDebtorsClerk(){
        return this.IsAccountingClerk('Debtor',this.Record.Business_Unitid) || this.userIsAdmin
      },
      LookupObj() {
       return { [this.CurrentEntity.Primary_Field_Name]: this.Record[this.CurrentEntity.Primary_Field_Name],
        id: this.Record.id,
        list: this.CurrentEntity.id,
        single: this.CurrentEntity.SingleName,
        identifier: this.CurrentEntity.Primary_Field_Name,
       }
        //but storeorederentity like cart itmes need these
        //SubColid: this.MasterTableObj.id,
        //SubColRecordid: newdocid although this one, see "LookupObjSelectedSalesOrder" in "InvoicePrepandExport" we remove it again before assign LoObj to inv??
      },
      TableCollectionRef(){
        return this.CurrentEntity ?  this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records') : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()) : ''
        //return db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase())
      },
      StatusField(){
            if(this.CurrentEntity){
                return this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
            }
        },
      ActiveLoanOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Cash Loan'
        })
      },
      ActiveStoreOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Store Order'
        })
      },
      UserCompanyUserInviteText(){
        let text = ''
        if(this.UserCompanyUserInvite){
          if(!this.Record.UserInvite){
            text = 'Invite User' 
          }
          else if(this.Record.UserInvite){
            text = 'Copy Invite Link' 
          }
        }
        return text
      },
      UserCanInvite(){
        return this.userLoggedIn && this.userLoggedIn.CanInviteUsers || this.userIsAdmin
      },
      UserCompanyUserInvite(){
        return this.CurrentEntity && this.CurrentEntity.id === 'User_Companies' && this.Record && !this.Record.User && this.Record.CanEdit && this.UserCanInvite
        //we need to do more...can this user even edit the record?
      },
      CashLoanEntity(){
            return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Cash Loan'
        },
      SystemBuilderTable(){
        return this.SystemisRA && this.CurrentEntity && this.CurrentEntity.id === 'Systems'
      },
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      WarehousesArray(){
      return this.$store.state.WarehousesArray
    },
      CanReceiveGoods(){
        return this.StoreProductEntity && this.Record.CanEdit && this.BypassInboundWIP
      },
       CurrentEntityApp(){
            return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
    },
      BypassInboundWIP(){
          let appplugins = []
          if(this.CurrentEntityApp){
            appplugins = this.CurrentEntityApp.Plugins.map(plug => {return plug.id})
          }
          return this.CurrentEntityApp && !appplugins.includes('Warehouse_Inbound')
        },
      ActiveGroup(){
        return this.$store.state.ActiveGroup
      },
      PotentialOwnerUsersArray(){
            let entityprop = this.CurrentEntity.DisplayName.split(' ').join('')
            return this.UsersArray
            .filter(user => {
              //technically this checks whom I have permissions to assign to. Issue is I don't know if THEY have access...
                 let datasetsecuritystructure = this.CurrentEntity.Security_Structure.Name
                //console.log('PotentialOwnerUsersArray ',datasetsecuritystructure,entityprop+'editAll',this.$store.state[entityprop+'editAll'],this.$store.state)
                if(datasetsecuritystructure === 'Business Unit'){
                    return this.$store.state[entityprop+'editAll'] || 
                        this.$store.state[entityprop+'editUnitdown'] && this.userBUChildren.includes(user.Business_Unitid) || 
                        this.$store.state[entityprop+'editUserUnit'] && this.userBUID === user.Business_Unitid || 
                        this.$store.state[entityprop+'editOwner'] && this.userLoggedIn.id === user.id
                }
                else if(datasetsecuritystructure === 'Custom Roles'){
                    return this.$store.state[entityprop+'CanCreate']
                }
                else if(datasetsecuritystructure === 'Group Member'){
                        return this.ActiveGroup.TeamMembers.includes(user.id) || this.ActiveGroup.Administrators.includes(user.id)
                    }   
                
            })
        },
      UsersArray(){
      return this.$store.state.UsersArray
    },
      OwnerSecurity(){
        return this.CurrentEntity.Security_Structure.Name === 'Business Unit'
      },
      HasNavlist(){
        if(this.StoreArticleEntity){
         let obj = {CategoricalListType: 'Store Products',HidePrice: true,HideTitle: true,
        HideCart: true,HideWishlist: true,DataRoute: true,
        TagField: this.CurrentEntityFields.find(obj =>obj.id === 'Product_Code'),
        IMGField: 'IMG',
        DescriptionField: 'Descriptive_Text',
        SingleName: this.CurrentEntity.SingleName}
        //console.log(obj)
        return obj 
        }
        else if(this.SystemBuilderEntity){
         let obj = {CategoricalListType: 'Store Products',HidePrice: true,HideTitle: true,
        HideCart: true,HideWishlist: true,DataRoute: true,
        TagField: this.CurrentEntityFields.find(obj =>obj.id === 'Name'),
        IMGField: 'Suite_Logo',
        DescriptionField: 'Description',
        SingleName: this.CurrentEntity.SingleName}
        //console.log(obj)
        return obj 
        }
        
      },
      StoreArticleEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
      },
      StoreProductEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      StoreOrderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Order'
      },
      TableEntryPath(){
        return this.CurrentEntity.SingleName.split(' ').join('')
      },
      RecordNotifications(){
        return this.UnreadNotifications.filter(not => {
                    return not.clientrecords === this.Record.id
                })
      },
      ComputedRecordQuickNavItems(){
        return this.RecordQuickNavItems.map(item => {
          if(this.MiniView){
            item.Class = item.Color+' recordcabinetdrawermini  my-1' 
          }
          else{
            item.Class = item.Color+' recordcabinetdrawer my-1'
          }
          if(item.Name === 'Liaison'){
            item.Notifications = this.RecordNotifications.filter(not => {
                    return not.IsLiaison
                })
                if(item.Notifications.length > 0){
                    item.notifications = item.Notifications.length
                }
                else{
                  item.notifications = ''
                }
          }
          else if(item.Name === 'Activities'){
            item.Notifications = this.RecordNotifications.filter(not => {
                    return not.Type.includes('Task')
                })
                if(item.Notifications.length > 0){
                    item.notifications = item.Notifications.length
                }
                else{
                  item.notifications = ''
                }
          }
          //console.log(item.Name)
          return item
        }).filter(item => {
          if(item.Pluginid){
            return this.ActiveSuiteApp.Plugins.find(obj => obj.id === item.Pluginid)
            || this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
          }
          else if(item.ComputedProp){
            return this[item.ComputedProp]
          }
          else{
            return item
          }
        })
        //should check plugins
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ConfigDB(){
        return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
        PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
      AppQuickDashPlugin(){
        return this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Quick_Dashboards')
      },
      QuickEditEntity(){
        return this.StoreArticleEntity || this.StoreProductEntity || this.CalendarTypeEntity || this.SystemBuilderEntity
      },
      SystemBuilderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'System Builder'
      },
      CalendarTable(){
        return this.CurrentEntity.Entity_Type.Name === 'Calendar'
      },
      CalendarTypeEntity(){
        return this.ScheduleBookingEntity || this.CalendarTable
      },
      ScheduleBookingEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Booking Schedule'
      },
      StoreArticleEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
      },
      StoreProductEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      StoreOrderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Order'
      },
      QuickEditEntity(){
        return this.StoreArticleEntity || this.StoreProductEntity || this.CalendarTypeEntity || this.SystemBuilderEntity
      },
      SystemBuilderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'System Builder'
      },  
      QuickEditSections(){
        if(this.StoreArticleEntity){
          let sect1fields = ['Product_Code','Product_Group','Item_Type','UOM','PrimaryCategoryField','Descriptive_Text']
          let sect2fields = ['Image','Purchase_Leadtime','Supply_Leadtime','Last_Quoted_Price','Last_Quoted_Price_Date','Store_Price']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary',CanCreate: this.Record && this.Record.CanEdit},
          ]
        }
        else if(this.SystemBuilderEntity){
          let sect1fields = ['Name','BillingAccount','Business_Unit']
         let sect2fields = [
          {id: 'Suite_Logo',Name: 'Suite_Logo',DisplayName: 'Suite Logo',text: 'Suite Logo',value: 'Suite_Logo',
          Type: 'Single File Upload',FeaturedPhoto: true},
          {id: 'App_Icon',Name: 'App_Icon',DisplayName: 'App Icon',text: 'App Icon',value: 'App_Icon',
          Type: 'Single File Upload',FeaturedPhoto: true}]
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields).concat([{Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description'}])
            ,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Images',Type: 'Fields',Fields: sect2fields,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Plugins',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: {}
            ,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/SuiteBuilder/Main/RASuiteSubscriptions'),}
          ]
        }
        else if(this.StoreProductEntity){
          let sect1fields = ['Item_Name','Inventory_Article','Cost_Price','Markup','Discount','Store_Price']
          let sect2fields = ['On_Order','Backorder','Inbound','Received','On_Hand']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Movement',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary'},
          ]
        }
        else if(this.ScheduleBookingEntity){
          let sect1fields = ['Title','Type','Owner','Presenter','Description']
          let sect2fields = ['Image']
          let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          let bookingsubcol = this.CurrentEntity.SubCollections[0]
          let bookedusers = this.Record && this.Record.BookedUsers ? this.Record.BookedUsers.map(booking => {
            let userobj = this.UsersArray.find(obj => obj.id === booking.id)
            userobj.BookingObj = booking
            return userobj
          }) : []
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Graphics',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Booking Settings',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect3fields),Class: 'flex xl12 lg12 md6 sm12 xs12',
             Tooltip: this.RSVPUserLimit+`<br>`+this.RSVPSoonestTooltip+`<br>`+this.RSVPLatestTooltip
            },
            {Header: 'Bookings',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: bookingsubcol,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/General/UserLookupTable'),
            UsersLookupArray: bookedusers,UserHeaders: bookingsubcol.Headers,AdditionalHeaders: []}
          ]
        }
        else if(this.CalendarTable){
          //tricky but essentially 'Owner' applies if bu structure entity
          let sect1fields = ['Title','Description']
          //no image it would seem?
          //let sect2fields = ['Image']
          //let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          //HAPPPY THAT NOBODY CAN BOOK ON CALENDAR RIGHT? ALSO WHAT ABOUT CUSTOM SUBCOLS? QUICKEDIT?
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
          ]
        }
        
        else{
          return []
        }
      },
      MyActivitiesArray(){
      return []
    },
    ActivitiesArray(){
      return []
    },
    AppointmentsArray(){
      return []
    },
      ComputedActivityType(){
        if(this.ActivityType){
          return this.ActivityType
        }
        else{
          return {
            countname: 'None',
            tasks: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id}),
            apointments: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id}),
          }
        }
      },
        RelatedObj(){
        return this.Record && this.Record.id ? 
        {id: this.Record.id, [this.CurrentEntity.Primary_Field_Name]: this.Record[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,
        collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),Link: this.CurrentEntity.SingleName.split(' ').join('') }
        : ''
      },
      ActiveSuiteid(){
        return this.$store.state.ActiveSuiteid
      },
      roottableentities(){
        return this.$store.state.roottableentities
        },
      ItemOperationalDB(){
        return this.Record && this.Record.id ? 
        //db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id)
          this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.Record.id) : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id) : ''
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        
        CurrentEntityFields(){
        return this.CurrentEntity.AllFields.map(field => {
          let fieldobj = Object.assign({},field)
          return fieldobj
        })
      },
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
        return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.TableBuilder || 
              this.$store.state.NeedsRASync && this.SystemConfigorRA && this.tab.Entityid === 'entities'           
        },
    },
    watch: {
        Record: {
          handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
              }
          },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignInviteid']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.CurrentEntity && this.CurrentEntity.id === 'User_Companies' && this.userisDebtorsClerk){
        this.GuestView = true
      }
      this.NewStatus = this.Record.Status
      this.NewStatusReason = this.Record.Status_Reason
      alert('ahoi')
      
    },
    methods:{
      ActivateInvoicePaymentDialog(){
        this.paymentreceipt.Amount_Paid = Number(this.Record.Grand_Total.toFixed(2))
        //console.log(this.paymentreceipt)
        this.InvoicePaymentDialog = true
      },
      UploadFileSelectPOP(file,field,Record){
        //console.log(file,field,Record)
        Record[field.Name] = {UploadFile: file, FileName: file.name}
        },
      UploadPOP(){
        let vm = this
        //SelectedPOPFile
        //paymentreceipt.Receiving_Document.UploadFile
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Uploading POP')
        let file = vm.paymentreceipt.SelectedPOPFile.UploadFile
        let storagelocation = 'Sales_Invoices/'+vm.Record.id+'/Payments/'+file.name+new Date()
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(file);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Capturing Details')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: file.name,
                            filename: file.name
                          }
                          this.ProcessInvoicePayment(PDF_File)
                        })
      },
      ProcessInvoicePayment(PDF_File){
        this.$store.commit('setCustomProcessingDialogText','Updating Invoice')
        //PaidinFull
        db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Sales_Invoices').collection('Records').doc(this.Record.id).update({
          Amount_Paid: Number(this.paymentreceipt.Amount_Paid),
          Proof_of_Payment: PDF_File,
          Progress: {ID: 1000004, Name: 'Paid'}
        }).then(quoteupdatedoc => {
          this.CloseInvoicePaymentDialog()
          this.$store.commit('setCustomProcessingDialog',false)
        })
      },
      CloseInvoicePaymentDialog(){
        this.InvoicePaymentDialog = false
        this.paymentreceipt = {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          }
      },
      IsAccountingClerk(accountingclerkprop,BUid){
        return this.userLoggedIn.BusinessAccounting && BUid && this.userLoggedIn.BusinessAccounting[BUid] && this.userLoggedIn.BusinessAccounting[BUid][accountingclerkprop]
      },
      UpdateItemStatus(){
        let NewDataObj = {
          Status: this.NewStatus,
          Status_Reason: this.NewStatusReason
        }
        if(this.CashLoanEntity && this.NewStatusReason.Name === 'Processed'){
          if(this.userisDebtorsClerk){
            this.Record.Status = this.NewStatus
            this.Record.Status_Reason = this.NewStatusReason
            this.PrepInvoice = true
          }
          else{              
              this.DeactivateStateDialog()
          }
        }
        else{
          this.TableCollectionRef.doc(this.Record.id).update(NewDataObj).then(updatedobj => {
          this.DeactivateStateDialog()
        })
        }
        
      },
      DeactivateStateDialog(){   
        this.StateDialog = false  
        this.PrepInvoice = false
        this.NewStatus = this.Record.Status
        this.NewStatusReason = this.Record.Status_Reason
      },
      ActivateStateDialog(item){
        this.StateDialog = true
      },
      UpdateUserCompanyProp(prop,value){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('usercompanies').doc(this.Record.id).update({
          [prop] : value
        })
      },
      AddfieldOnceOff(){
        let newfield = {Name: 'Company', DisplayName: 'Company', Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'User_Companies',Pluginid: 'User_Companies',
        RelatedLocation: 'UserCompany', RelatedRecord: 'User Companies',Primary: false, Created: true, IsMandatory: false, IsHeader: true, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
        if(this.NeedsRASync){
          this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(rausercolfield => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(configusercolfield => {
              this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(rafield => {
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(configfield => {
                  
                })
              })
            })
          })
        }
        else{
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(configusercolfield => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(configfield => {
                  
                })
          })
        }
        
      },
      CopyInviteLink(fieldname){
        let route = '/UserInvite/'+this.Record[fieldname+'Invite'] 
        let successmsg = 'Copied link for '+fieldname+' Invite '+this.Record[fieldname+'Invite']
        let payload = {route: route,successmsg: successmsg}
        //console.log(payload)
        this.$store.dispatch('CopyRouteToClipBoard',payload)
        //this.CopyRouteToClipBoard(route,successmsg)
        //this.CopyRecordLink
      },
      AssignInviteid(result){
        //console.log('AssignInviteid',this.ItemOperationalDB)
        this.ItemOperationalDB.update({
          [result.RelatedField+'Invite']: result.InviteID
        })
      },
      InviteFieldValueUser(fieldname){
        if(this.Record[fieldname+'Invite']){
          this.CopyInviteLink(fieldname)
        }
        else{
          this.$store.commit('SetSocialItemInteractMethodProp',{RelatedObj: this.RelatedObj,RelatedField: fieldname}) 
          this.$store.commit('SetSocialItemInteractMethod','AcceptInviteUserDialog')
        }        
      },
      save(byQuickStep){
        this.$emit('save',byQuickStep)
      },
      SaveReceivingItem(){
      let vm = this
      let file = vm.editedReceivingItem.Receiving_Document.UploadFile
      let filename = vm.editedReceivingItem.Receiving_Document.FileName
      if(this.editedReceivingItemIndex === -1){
        //console.log(this.ItemOperationalDB,this.editedReceivingItem)
        let storepath = this.CurrentEntity.DisplayName.split(' ').join('')+'/GoodsReceiving/'+filename+'/'+new Date()
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$store.commit('setCustomProcessingDialogText','Uploading GRV')
        vm.$store.commit('setCustomProcessingDialog',true)
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
              
            //  let snackbarcontent = {
            //   snackbartimeout: 4000,
            //   snackbartext: 'Added '+field.DisplayName+' to - '+vm.Record[vm.CurrentEntity.PrimaryFieldName],
            //   snackbartop: true,
            // }
            // vm.$emit('ActivateSnackbar',true,snackbarcontent)
            delete vm.editedReceivingItem.Receiving_Document
            let GRVDoc = Object.assign({},vm.editedReceivingItem)
            GRVDoc.Receiving_Document = UploadObject
            GRVDoc.Date_Received = new Date(vm.editedReceivingItem.Date_Received)
            GRVDoc.Created_On = new Date()
            GRVDoc.Modified_On = new Date()
            GRVDoc.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
            GRVDoc.Created_Byid = this.userLoggedIn.id
            GRVDoc.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
            GRVDoc.Modified_Byid = this.userLoggedIn.id
            GRVDoc.StockObj = {
              [this.CurrentEntity.Primary_Field_Name]: this.Record[this.CurrentEntity.Primary_Field_Name],
              id: this.Record.id,
              list: this.CurrentEntity.id,
              single: this.CurrentEntity.SingleName,
              identifier: this.CurrentEntity.Primary_Field_Name
            }
            GRVDoc.Warehouseid = vm.Record.Warehouseid
            if(vm.Record.AddedtoStore){
              GRVDoc.StockObj.UpdateStore = vm.Record.AddedtoStore
              let Inventory_ArticleField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Inventory_Article')
              if(Inventory_ArticleField && vm.Record.Warehouseid && vm.Record.Inventory_Articleid){
                GRVDoc.StockObj.StoreCollection = Inventory_ArticleField.RelatedBuildID
                GRVDoc.StockObj.WarehouseDoc = vm.Record.Inventory_Articleid
                GRVDoc.StockObj.WarehouseID = vm.Record.Warehouseid
              }
            }
            vm.$store.commit('setCustomProcessingDialogText','Assigning GRV Number')
            const functions = firebase.functions();
             const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              let payload = {
                Docid: 'GoodsReceivingVouchers_GRV_Number',
                Data: {},
                siteid: vm.$store.state.ActiveSuiteid
              }
              AssignAutoNumber(payload).then(result => {
                vm.$store.commit('setCustomProcessingDialogText','Submitting to Server')
                GRVDoc.GRV_Number = result.data
                let bulkoutboundpayload = {
                  Collection: 'GoodsReceivingVouchers',
                  Data: [],
                  ParentObj: GRVDoc,
                  Warehouse: vm.Record.Warehouseid,
                  siteid: vm.$store.state.ActiveSuiteid
                }
                let movementdoc = JSON.parse(JSON.stringify(bulkoutboundpayload))
                movementdoc.Data = GRVDoc
                bulkoutboundpayload.Data.push(movementdoc)
                //console.log('bulkoutboundpayload',bulkoutboundpayload)
                
                const ReceiveOrderlessGRV = functions.httpsCallable('ReceiveOrderlessGRV');
                ReceiveOrderlessGRV(bulkoutboundpayload).then(result => {
                  vm.$store.commit('setCustomProcessingDialogText','Server Response - OK')
                  let warehouse = vm.WarehousesArray.find(obj => obj.id === vm.Record.Warehouseid)
                  if(warehouse && warehouse.TransactionNotificationParty){
                    vm.SendTransactionNotificationEmail(warehouse.TransactionNotificationParty)
                  }
                  else{
                    vm.DeactivateReceivingDialog()
                  }
                    //console.log(result)
                  }) 
              })
            //insert autonumber somehwere here

            //needs to actually become ConsumeStockQty
            // db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('Warehouses').doc(vm.Record.Warehouseid).collection('GoodsReceivingVouchers').add(vm.editedReceivingItem).then(grdoc => {
            //   vm.DeactivateReceivingDialog()
            // })
            
          })          
      }
    },
    SendTransactionNotificationEmail(emailtousers){
      let emailto = ''
      let emailtolength = emailtousers.length
      emailtousers.map((user,userindex) => {
        let userobj = this.UsersArray.find(obj => obj.id === user)
        if(userobj && userobj.Email){
          emailto = emailto+userobj.Email
          if(userindex-1+2 < emailtolength){
            emailto = emailto+', '
          }
        }
          if(userindex-1+2 === emailtolength){
            this.ProceedTransactionEmailNotification(emailto,this.ReceivingFields.filter(field => {return field.id !== 'Receiving_Document'}),this.editedReceivingItem)
          }
      })

    },
    ProceedTransactionEmailNotification(emailto,FormFields,newrecord){
      let vm = this
      let emailsubject = `<h2>New GRV on `+this.System.Name+` `+this.CurrentEntity.SingleName+` `+this.Record[this.CurrentEntity.Primary_Field_Name]+` </h2>`
      let EmailBody = `<html>
<body>
<head></head>
`+emailsubject+
`<p>This is an automated notification about a GRV on the subjected entry</p>
<table>`
             let length = FormFields.length
            FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.DisplayName+`:</th>
<td align="left">`+newrecord[field.Name]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>`
                 } 
            })
            let link = this.System.ClientAppURL+'/'+this.TableEntryPath+'/'+this.Record.id
          EmailBody = EmailBody+`<p>To view the details more details, click <a href="`+link+`">here</a></p>` 
          EmailBody = EmailBody+`<i>This update was made by `+this.userLoggedIn.Full_Name+` on `+new Date()+`</i>`  
          EmailBody = EmailBody+`
</body>
</html>`  
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: emailto,
                EmailSubject: emailsubject,
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
               vm.DeactivateReceivingDialog()
            })  
    },
      DeactivateReceivingDialog(){
      this.ReceivingDialog = false
      this.editedReceivingItem = {}
      this.$store.commit('setCustomProcessingDialog',false)
    },
      ActivateReceivingDialog(){
      if(this.BypassInboundWIP){
        let progressfield = this.ReceivingFields.find(obj => obj.id === 'Progress')
        progressfield.ReadOnly = true
        this.editedReceivingItem.Progress = progressfield.Options[progressfield.Options.length-1]
      }
      this.ReceivingDialog = true
    },
      ActivateActiveTab(section){
        this.$emit('ActivateActiveTab',section)
      },
      UpdateOwningUser(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            this.AssignBU()
        },
      AssignBU(){
      let userid = this.Record.Owner.id
      let ownerobj = {
        id: this.Record.Owner.id,
        Full_Name: this.Record.Owner.Full_Name,
        Name: this.Record.Owner.Name,
        Surname: this.Record.Owner.Surname
      }
      this.CreateLiaisonNotificationtoOwner(this.Record)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        let buobj = {
          id: BUId,
          Name: BUdata.Name
        }
        this.ItemOperationalDB.update({
          Owner: ownerobj,
          Ownerid: ownerobj.id,
          Business_Unit: buobj,
          Business_Unitid: buobj.id
        })        
      })
      })
    },
    CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.CurrentEntity.DisplayName,
          recordlocation: '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.CurrentEntity.id,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        //console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){      
      //db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(record.id).collection('InternalLiaison')
        let commscol = this.ItemOperationalDB.collection('InternalLiaison')
        //console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.Primary_Field_Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.Primary_Field_Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
      CopyRecordLink(item){
        let route = '/'+this.TableEntryPath+'/'+item.id
        let successmsg = 'Copied link for '+item[this.CurrentEntity.Primary_Field_Name]
        let payload = {route: route,successmsg: successmsg}
        console.log(payload)
        this.$store.dispatch('CopyRouteToClipBoard',payload)
      },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      ActivateProcessing(bool){
        this.$emit('ActivateProcessing',bool)
      },
      ViewItem(item){
        this.$emit('ViewItem',item,true)
      },
        ReduceQuickStep(){
            this.$emit('ReduceQuickStep')
        },
        PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = Object.assign({},this.CurrentEntityFields.find(obj => obj.id === field))
            if(fieldobj){
              if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.Record[fieldobj.Name] !== 'undefined' && typeof this.Record[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.Record[fieldobj.Name].toDate()
                this.Record[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            if(fieldobj.Type === 'Calculated Field'){
              this.Record[fieldobj.Name] = this.PerformedCalculation(this.Record,fieldobj)
            }
            }            
            return fieldobj
          }).filter(fieldobj => {
            return fieldobj && fieldobj.Name
          })
      },
      
      SelectImage(tab, tapprop, asset, IsThis,SelectName){
        //console.log(tab, tapprop, asset, IsThis,SelectName)
        if(SelectName === 'Set Primary'){
          this.SelectPrimaryImage(asset)
        }
      },
      SelectPrimaryImage(asset){
        if(this.PrimaryImageFieldName){
          this.Record[this.PrimaryImageFieldName] = Object.assign({},asset)
          this.Record[this.PrimaryImageFieldName+'ThumbURL'] = asset.ThumbURL
        }
        this.quickeditstep = 1
        setTimeout(() => {
          this.quickeditstep = 2  
        }, 1);
              
        //console.log(this.Record)
      },
      AddGallerySubcolImages(event,item,subcolid){ 
      let fileList = event.target.files || e.dataTransfer.files
      this.$store.commit('setUploadFilesList',fileList)
      let sectionstorageref = subcolid.split(' ').join('')+'/'+item.id+'/'
      let basestorageref = this.TableNameSplit+'/'+item.id+'/'
      let filestorageref = basestorageref+sectionstorageref
      let dbrecordref = this.TableCollectionRef.doc(item.id).collection(subcolid.split(' ').join('').split('_').join('').toLowerCase())
      this.$store.commit('setUploadFileStorageRef',filestorageref)
      this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      UploadFileSelectAlternate(file,field,Record){
      //console.log(file,field,Record)
      Record[field.Name] = {UploadFile: file, FileName: file.name}
    },
      UploadFileSelect(event,field,Record){
        console.log(event,field,Record)
        let file = event.target.files[0]
        Record[field.Name] = {UploadFile: file, FileName: file.name,localURL: URL.createObjectURL(file)}
        if(this.QuickMode === 'QuickEdit'){
       this.QuickMode = ''
        setTimeout(() => {
          this.QuickMode = 'QuickEdit'
        }, 10);
        }
        },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
        ActiveComponent(ComponentPath){
            return () => import(`@/`+ComponentPath+`.vue`);	 
        },
      ToggleQuickMode(Name,item){
        this.ActiveUnreadNotifications = []
        if(Name === 'QuickLiaison'){
          this.LiaisonDialogActive = true
        }
        else{
          this.LiaisonDialogActive = false
        }
        this.$emit('ToggleQuickMode',Name)
        if(item && item.Notifications){
          this.ActiveUnreadNotifications = item.Notifications
          // item.Notifications.map(not => {
          //     db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(not.id).update({
          //       Read: true
          //     })
          //   })
        }
      },
      ToggleLiaisonDialog(){
      this.LiaisonDialogActive = !this.LiaisonDialogActive
    },
    }
}
</script>

<style>

</style>